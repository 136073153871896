export const EdgeAPI = {
  ArticleHealthScoresandIssues:
    "/edge/Foliko/Company/GetArticleHealthScoreAndIssues?",
  Articles: "/edge/Foliko/Portfolio/ListArticles",
  ArticlesCompanyLevel1: "/edge/Foliko/ArticlesCompany/GetLevel1s",
  ArticlesRelatedIssues: "/edge/Foliko/Articles/RelatedIssues",
  Benchmark: "/edge/Foliko/Benchmark/List",
  BenchmarkHealthScores: "/edge/Foliko/Benchmarks/GetHealthScores",
  BenchmarkHistoricalStockData: "/edge/Foliko/Benchmark/GetHistoricalStockData",
  BenchmarkPredictions: "/edge/Foliko/Benchmarks/GetPredictions",
  BottomRankedCompaniesByPriceTarget:
    "/edge/Foliko/Companies/ListBottomRankedByPriceTarget",
  CompaniesMovingAverage: "/edge/Foliko/Companies/GetMovingAverageData",
  CompanyDescription: "/edge_public/company_description",
  CompanyLeaderboard: "/edge/Foliko/Leaderboard/Company",
  CompanyNarrativesList: "/edge/Company/Narratives_2/List",
  CompanyWindowedPricePerformance:
    "/edge/Foliko/Company/GetWindowedPricePerformance",
  CreateDefaultPortfolio: "/edge/Portfolio/CreateDefault",
  Fundamentals: "/edge/Foliko/Companies/GetFundamentals",
  FundamentalsPublic: "edge_public/company_info",
  GetAllNarrativePaths: "edge_public/narrative_paths",
  GetArticleFromSlug: "/edge_public/article",
  GetArticleImageFromSlug: "/edge_public/article_img",
  GetCompaniesFromTickers: "/edge_public/company_info_from_tickers",
  GetCompanyByTicker: "/edge/Foliko/Company/LoadData",
  GetNarrativeEnrichments: "edge_public/article_enrichments",
  GetTopCompanies: "edge_public/snapshot_top_companies",
  GetYouTubePreviewInfo: "/youtube_public/preview_info/",
  HealthScore: "/edge/Foliko/Company/GetMovingAverageData",
  HealthScores: "/edge/Foliko/Sectors/GetHealthScores",
  HistoricalData: "/edge/Foliko/Stock/GetHistoricalData",
  HistoricalNarratives: "/edge_public/historical_narratives",
  HomeNewsCoverage: "/edge_public/list_homepage_news_coverage",
  IssuesRelatedHistoricalStockPrices:
    "/edge/Foliko/Issues/RelatedHistoricalStockPrices",
  LeaderboardSector: "/edge/Foliko/Leaderboard/Sector",
  List1W1M6MStockClosePrice: "/edge/Foliko/Companies/List1W1M6MStockClosePrice",
  ListCompanyCoverage: "edge_public/list_company_coverage",
  NewsOutlets: "/edge/Foliko/Newsoutlet/List",
  Portfolio: "/edge/Portfolio/List",
  PortfolioAdd: "/edge/Portfolio/Add",
  PortfolioCompany: "/edge/PortfolioCompany/List",
  PortfolioCompanyAdd: "/edge/PortfolioCompany/Add",
  PortfolioCompanyAddBulk: "/edge/PortfolioCompany/Add/Bulk",
  PortfolioCompanyDelete: "/edge/PortfolioCompany/DeleteAlternate",
  PortfolioDelete: "/edge/Portfolio/Delete",
  PortfolioGroupList: "/edge/PortfolioGroup/List",
  PortfolioUpdateBulk: "/edge/Portfolio/Update/Bulk",
  PotentialPortfolios: "/edge/Company/ListPotentialPortfolios",
  RankedCompaniesByPriceTarget:
    "/edge/Foliko/Sector/GetRankedCompaniesByPriceTarget",
  RankedPredictions: "/edge/Foliko/Sectors/GetRankedPredictions",
  RecentStockClosePrice: "/edge/Foliko/Companies/ListRecentStockClosePrice",
  SearchCompany: "/edge/Foliko/Company/Search",
  SectorCompanies: "/edge/Foliko/Sectors/ListCompanies",
  SectorCompanyNews: "/edge/Foliko/Companies/ListArticles",
  SectorHistoricalStockData: "/edge/Foliko/Sector/GetHistoricalStockData",
  SectorNews: "/edge/Foliko/Sector/ListArticles",
  SectorPortfolioFundamentals: "/edge/Foliko/SectorPortfolio/GetFundamentals",
  SectorPortfolioHealthFactor: "/edge/Foliko/SectorPortfolio/GetHealthFactor",
  SectorPortfolioPricePerformance:
    "/edge/Foliko/SectorPortfolio/GetPricePerformance",
  SectorPortfolioValuation: "/edge/Foliko/SectorPortfolio/Valuation",
  StockPrediction: "/edge/Foliko/Stock/GetPredictionData",
  TopNIssues: "/edge/Foliko/Issues/ListTopN",
  TopNarratives: "/edge_public/top_narratives",
  TopRankedCompaniesByPriceTarget:
    "/edge/Foliko/Companies/ListTopRankedByPriceTarget",
  TopRankedSectorsByHealthFactor:
    "/edge/Foliko/Sectors/ListTopRankedByHealthFactor",
};
