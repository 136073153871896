import React from "react";

import { EdgeAPI } from "@/api/Edge";
import { useGetFetch } from "@/hooks/useGetFetch";
import { NarrativeEnrichment, NarrativeSummary } from "@/model/api";
import { getArticleBaseUrl } from "@/util/newsletter";

interface Props {
  narrativeInfo: NarrativeSummary;
  ticker: string;
  truncate: boolean;
}

const ArticleTeaser: React.FC<Props> = React.memo(
  ({ narrativeInfo, ticker, truncate }) => {
    const {
      loading: descLoading,
      error: descError,
      data: descData,
    }: {
      data: NarrativeEnrichment;
      error: Error | undefined;
      loading: boolean;
    } = useGetFetch(
      `${EdgeAPI.GetNarrativeEnrichments}/${getArticleBaseUrl(narrativeInfo)}`,
      [ticker]
    );

    return !descLoading ? (
      <p
        className={`text-dolphin-gray ${
          !truncate ? "text-sm" : "text-[12px] line-clamp-2"
        } my-2`}
      >
        {descData.Teaser}
      </p>
    ) : (
      <div className="animate-pulse space-y-2 my-4">
        <div className="w-full h-3 rounded-full bg-gray-200"></div>
        <div className="w-full h-3 rounded-full bg-gray-200"></div>
        <div className="w-full h-3 rounded-full bg-gray-200"></div>
        <div className="w-1/4 h-3 rounded-full bg-gray-200"></div>
      </div>
    );
  }
);

ArticleTeaser.displayName = "ArticleTeaser";

export default ArticleTeaser;
