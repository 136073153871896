import Link from "next/link";
import { memo } from "react";

import { BarTitle } from "@/components/ui/folikoInsights/BarTitle";
import useIsMobile from "@/hooks/useIsMobileDevice";
import { NarrativeSummary, TopNarratives } from "@/model/api";
import { range, truncateHeadline } from "@/util";
import { getArticleUrl, getTickerUrl } from "@/util/newsletter";

import MarketNewsMobile from "./MarketNewsMobile";

interface Props {
  list: TopNarratives;
  loading: boolean;
}

const MarketNewsLoading = () => {
  return (
    <ul className="animate-pulse mt-4 space-y-6">
      {range(1, 10).map((id, index) => (
        <li
          key={index}
          className="scale-100 transition-all duration-150 border-gray-300 sm:border-b-2 rounded-none relative pb-3 w-full"
        >
          <div className="flex flex-col h-full w-full space-y-2">
            <div className="h-4 bg-gray-200 rounded-full w-24"></div>
            <div className="h-4 bg-gray-200 rounded-full w-full"></div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const MarketNews: React.FC<Props> = memo(({ list, loading }) => {
  const { isMobile } = useIsMobile();

  if (loading) {
    return <MarketNewsLoading />;
  }

  if (isMobile) {
    return <MarketNewsMobile list={list} />;
  }

  return (
    <div className="sm:mt-4 px-4 ">
      <div className=" mb-6 pb-1 border-t-dolphin-silver border-b ">
        <BarTitle title="Today's Market News" className="text-xl sm:text-lg" />
      </div>
      <ul className="flex flex-col w-full justify-between space-y-4 ">
        {list["TopSecondary"].map((narrative: NarrativeSummary, index) => {
          const articleLink = getArticleUrl(narrative, "top-20");

          return (
            <li
              key={`${narrative.TopicId}-${narrative.Date}-${index}`}
              className="scale-100 transition-all duration-150 border-t-dolphin-silver border-b last:border-b-0 rounded-none relative py-3 w-full"
            >
              <div className="flex flex-col h-full w-full">
                <Link href={getTickerUrl(narrative.Ticker, "top-20")} passHref>
                  <a className="text-fi-red text-xs font-bold cursor-pointer hover:text-blue-light group">
                    <span>{narrative.Ticker}&nbsp; - &nbsp;</span>
                    <span className="text-gray-500 font-normal group-hover:text-fi-black group-hover:font-bold">
                      {narrative.CompanyName}
                    </span>
                  </a>
                </Link>
                <Link href={articleLink} passHref>
                  <a className="text-xs sm:text-sm font-semibold leading-tight text-fi-black cursor-pointer hover:text-fi-red">
                    {narrative.Headline}
                  </a>
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

MarketNews.displayName = "MarketNews";
