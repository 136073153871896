import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export const useGetFetch = (
  url: string,
  dependencies: any[] = [],
  immediate = true,
  fn: (currurl: string, data?: any) => Promise<any> = axios.get
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<any>();

  const fetchData = useCallback((currurl: string) => {
    setData(undefined);
    setLoading(true);
    fn(currurl)
      .then((res: any) => res.data)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    if (immediate == true) {
      fetchData(url);
    } else {
      setData(undefined);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, immediate]);

  return { data, error, fetchData, loading };
};
