import React from "react";

import { EdgeAPI } from "@/api/Edge";
import { InsightsList } from "@/components/newFoliko/home/insightsList/InsightsList";
import { MarketNews } from "@/components/newFoliko/home/marketNews/MarketNews";
import MostImpactful from "@/components/newFoliko/home/most-impactful";
import { useGetFetch } from "@/hooks/useGetFetch";
import { HomeLayoutV2 } from "@/layouts/HomeLayoutV2";
import { TopNarratives } from "@/model/api";
import { FolikoPage } from "@/model/page";
import { BaseUrl } from "@/util/app";

const HomeV2 = () => {
  const {
    loading: topNarrativesLoading,
    error: topNarrativesError,
    data: topNarrativesData,
  }: {
    data: TopNarratives;
    error: Error | undefined;
    loading: boolean;
  } = useGetFetch(EdgeAPI.TopNarratives, []);

  return (
    <HomeLayoutV2>
      <div className="w-full md:w-1/5 bg-white md:bg-gray-100 rounded order-1 md:-order-none">
        <MarketNews loading={topNarrativesLoading} list={topNarrativesData} />
      </div>
      <div className="flex-1 border-t-dolphin-silver md:border-r md:px-8 px-0 order-0 md:-order-none">
        <MostImpactful
          loading={topNarrativesLoading}
          list={topNarrativesData}
        />
      </div>
      <div className="w-full md:w-1/5 order-2 md:-order-none md:pl-8 pl-0">
        <div className="flex flex-col items-start justify-center">
          <InsightsList
            loading={topNarrativesLoading}
            list={topNarrativesData}
            listParam="TopWeekly"
          />
          <InsightsList
            loading={topNarrativesLoading}
            list={topNarrativesData}
            listParam="Trending"
          />
        </div>
      </div>
    </HomeLayoutV2>
  );
};

const MemoizedHomeV2: FolikoPage = React.memo(HomeV2);

MemoizedHomeV2.getMetadata = () => ({
  pageDescription: () => "Investor news for a new age.",
  pageThumbnail: () => `${BaseUrl}/img/thumbnails/foliko-thumb-teaser.png`,
  pageTitle: () => "Foliko Insights - AI Powered Investor News",
  pageUrl: () => `${BaseUrl}`,
});

export default MemoizedHomeV2;
